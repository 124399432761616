import React, { useEffect, useRef } from 'react';
import * as d3 from "d3";
import Box from '@mui/material/Box';

const AGE_MAX = 100
const CELL_SIZE = 8
const COLOR_POOL = ['#35495e', '#41b883']

function Weeks(props) {
  const ref = useRef()
  const {birthday} = props;
  
  useEffect(() => {
    draw(ref, birthday)
  });

  useEffect(() => {
    updateDraw(ref, birthday)
  }, [birthday]);

  return (
    <Box sx={{ p: 1, border: '1px' }}>
      <svg
        ref={ref}
      />
    </Box>
  );
}

function draw(ref, birthday) {
  // TODO: update data
  // const data = [
  //   {year_num: 0, week_num: 0, color: 0}, {year_num: 0, week_num: 1, color: 1},
  //   {year_num: 1, week_num: 0, color: 1}, {year_num: 1, week_num: 1, color: 0}
  // ]
  const data = genWeekData(birthday)

  let scale = d3.scaleLinear()
    .domain([0, 90]) // Data space
    .range([0, 900]) // Pixel space
  const svg = d3.select(ref.current)
  svg.attr('width', 520)
  svg.attr('height', 900)
  svg.selectAll('rect').data(data).enter().append('rect')
    .attr('x', function (d) {
      return scale(d.week_num)
    }) // function (d, i) { return i * CELL_SIZE }
    .attr('y', function (d) {
      return scale(d.year_num)
    })
    .attr('width', CELL_SIZE)
    .attr('height', CELL_SIZE)
    .style('fill', d => COLOR_POOL[d.color])
}

function updateDraw (ref, birthday) {
  const data = genWeekData(birthday)
  d3.select(ref.current)
    .selectAll('rect').data(data)
    .style('fill', d => COLOR_POOL[d.color])
}

function genWeekData(birthday) {
  let i, j
  const data = []
  for (i = 0; i < AGE_MAX; i++) {
    for (j = 0; j < 52; j++) {
      data.push({ year_num: i, week_num: j, color: cal_base_color(i, j, birthday) })
    }
  }
  return data
}

function cal_base_color(year, week, birthday) {
  let [age_year, age_week] = cal_age_year_week(birthday);
  const birth_week = cal_week_number(birthday);

  if (age_year < 0) {
    return 1;
  }

  // Note: Tricky part, if birth_week is larger than age_week
  // then we need to let the data year starts from one year ahead.
  if (birth_week > age_week) {
    age_year += 1;
  }

  if (year === 0) {
    if (year === age_year) {
      return week < birth_week || week >= age_week ? 1 : 0;
    } else {
      return week < birth_week ? 1 : 0;
    }
  } else if (year < age_year) {
    return 0;
  } else if (year === age_year) {
    return week <= age_week ? 0 : 1;
  } else if (year > age_year) {
    return 1;
  }
}

function cal_age_year_week(birthday) {
  const today = new Date();
  const age_year = today.getFullYear() - birthday.getFullYear() - (today.getMonth() < birthday.getMonth() || (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate()) ? 1 : 0);
  const age_week = cal_week_number(today);

  return [age_year, age_week];
}

function cal_week_number(input_date) {
  const january_first = new Date(input_date.getFullYear(), 0, 1);
  const days_diff = (input_date - january_first) / (1000 * 60 * 60 * 24);
  let week_num = Math.floor(days_diff / 7);
  week_num = week_num >= 52 ? 51 : week_num;
  return week_num;
}

export default Weeks;