import React, {useEffect, useState} from 'react';
import Weeks from './components/Weeks';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import {ui, auth, logoutUser} from "./firebase_config";

import firebase from 'firebase/compat/app';
import {Button} from "@mui/material";

function App() {
    const [time, setTime] = useState(generateTimeString().next().value)
    const [birthday, setBirthday] = useState(dayjs(randomDate(new Date(1986, 0, 1), new Date())));
    const [user, setCurrentUser] = useState(null);

    useEffect(() => {
        if (user) {
            // If user already logged in, do nothing here.
            return
        }

        var uiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                    // User successfully signed in.
                    // Return type determines whether we continue the redirect automatically
                    // or whether we leave that to developer to handle.
                    // console.log(authResult.additionalUserInfo.profile)
                    // let d = dayjs(authResult.additionalUserInfo.profile.birthday, "MM-DD-YYYY");
                    return true;
                },
                uiShown: function () {
                    // The widget is rendered.
                    // Hide the loader.
                    // document.getElementById('loader').style.display = 'none';
                }
            },
            // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
            signInFlow: 'popup',
            signInSuccessUrl: '/',
            signInOptions: [
                // Leave the lines as is for the providers you want to offer your users.
                // {
                //     provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                //     scopes: [
                //         'public_profile',
                //         'email',
                //         'user_birthday '
                //     ],
                // },
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
                },
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
                }
            ],
        };
        ui.start('#firebaseui-auth-container', uiConfig);
    }, [birthday, user]);

    useEffect(() => {
        const timeInterval = setInterval(() => {
            let val = generateTimeString().next()
            setTime(val.value)
        }, 1000)

        const unsubscribe = auth.onAuthStateChanged(user => {
            if(user){
                // console.log(user)
                if (!user.reloadUserInfo.dateOfBirth) {
                    // TODO - Let user setup birthday
                    alert("We will let you set up your birthday later, for now, you were born in 1990-01-01 ")
                    setBirthday(dayjs("1990-01-01"))
                } else {
                    let d = dayjs(user.reloadUserInfo.dateOfBirth, "MM-DD-YYYY");
                    setBirthday(d)
                }
                setCurrentUser(user);
            }else{
                setCurrentUser(null);
                setBirthday(dayjs(randomDate(new Date(1986, 0, 1), new Date())))
            }
        });

        // Cleanup subscription on unmount
        return () => {
            clearInterval(timeInterval)
            unsubscribe();
        }
    }, []);

    return (
        <Container maxWidth="sm">
            <Box sx={{p: 1, flexGrow: 1}}>
                <Grid container spacing={2}>

                    <Grid item xs="auto">
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
                            <DatePicker disabled={user!==null} value={birthday} onChange={(newValue) => setBirthday(newValue)}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs="auto">
                        { !user ? <div id="firebaseui-auth-container"></div> :
                                <>
                                    <b>Hello {user.displayName}</b>&nbsp; &nbsp;
                                    <Button onClick={logoutUser}>Logout</Button>
                                </>
                        }
                    </Grid>
                </Grid>
            </Box>

            <Weeks birthday={birthday.toDate()}></Weeks>

            <Box sx={{ p: 1, flexGrow: '1px' }}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs="auto">
                        Carpe Diem
                    </Grid>
                    <Grid item xs="auto">
                        {time}
                    </Grid>
                </Grid>
            </Box>

        </Container>
    );
}

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

function* generateTimeString () {
    while (true) {
        let time = new Date()
        // let ms = time.getMilliseconds()
        // ms = '000' + ms
        // ms = ms.substr(ms.length - 3)
        // yield time.toLocaleTimeString() + ' \'' + ms
        yield time.toLocaleString()
    }
}


export default App;
