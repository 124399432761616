import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB-Ku5bIeKIRwg-OAWRxNm5zGTt8wsK4GU",
    authDomain: "lifeinweek-8e66a.firebaseapp.com",
    projectId: "lifeinweek-8e66a",
    storageBucket: "lifeinweek-8e66a.appspot.com",
    messagingSenderId: "7094455598",
    appId: "1:7094455598:web:42c22d84cdffbb65cdfebf",
    measurementId: "G-MLFC1Z1HM4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const ui = new firebaseui.auth.AuthUI(auth);

export const logoutUser = async (e) => {
    e.preventDefault();
    await signOut(auth);
}
